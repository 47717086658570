<template>
    <div class="contract-container">
        <StackRouterHeaderBar
            :left-button-handler="back"
            :left-button="`close`"
            :show-title="true"
            title="전자계약서 서명"
        />
        <div class="contract-container-main" :class="signStatus.mode">
            <div class="content">
                <ContractMainV2
                    :user-contract="userContract"
                    :signStatus="signStatus"
                    :contract-version="contractVersion"
                    :sign="sign"
                    :today="today"
                />
                <ContractTermV2 :contract-version="contractVersion" />
                <ContractPrivacyV2
                    :signStatus="signStatus"
                    :contract-version="contractVersion"
                    :sign="sign"
                    :today="today"
                />
                <ContractConsentV2
                    :signStatus="signStatus"
                    :contract-version="contractVersion"
                    :sign="sign"
                    :today="today"
                />
            </div>
        </div>
        <ContractProgressBtn v-if="mode === 'create'" @submitContract="submitContract" :signStatus="signStatus" />
    </div>
</template>

<script>
import { v2, v3, v4Prepaid, v1Postpaid } from '@/assets/constants/contract'
import userContractService from '@/services/user-contract'
import ContractProgressBtn from '@/routes/my-page/components/contract/ContractProgressBtn'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ContractV2',
    props: {
        userContract: {
            type: Object,
        },
    },
    data: () => ({
        sign: null,
        imgBlob: null,
        signStatus: {
            nowIdx: 0,
            clicked: [false, false, false],
            ids: ['main-sign', 'privacy-sign', 'consent-sign'],
            mode: 'create',
        },
    }),
    mounted() {
        if (this.userContract.is_complete) {
            this.signStatus.mode = 'import'
            this.sign = this.userContract.photo_url
        }
        this.$registerBackHandler(this.back)
        this.$bus.$on('getUserSign', this.getUserSign)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
        this.$bus.$off('getUserSign')
    },
    components: {
        ContractProgressBtn,
        ContractMainV2: () => import(/* webpackPrefetch: true */ '@/routes/my-page/components/contract/ContractMainV2'),
        ContractTermV2: () => import(/* webpackPrefetch: true */ '@/routes/my-page/components/contract/ContractTermV2'),
        ContractPrivacyV2: () =>
            import(/* webpackPrefetch: true */ '@/routes/my-page/components/contract/ContractPrivacyV2'),
        ContractConsentV2: () =>
            import(/* webpackPrefetch: true */ '@/routes/my-page/components/contract/ContractConsentV2'),
    },
    computed: {
        today() {
            const date = this.mode === 'create' ? new Date() : new Date(this.userContract.complete_time)

            return {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
            }
        },
        contractVersion() {
            const { plan_type: planType } = getPlanNameById(this.userContract.product_id)
            if (!(this.userContract || {}).version) {
                return planType === 'prepaid' ? v4Prepaid : v1Postpaid
            }
            const conVer = `v${this.userContract.version}${this.$case.toPascal(planType)}`
            // const contractVersion = 'v' + version+this.$case.toPascal(plan_type)
            console.log('c', conVer)
            switch (conVer) {
                case 'v4Prepaid':
                    return v4Prepaid
                case 'v3':
                case 'v3Postpaid':
                case 'v3Prepaid':
                    return v3
                case 'v2':
                case 'v2Postpaid':
                case 'v2Prepaid':
                    return v2
                case 'v1Postpaid':
                    return v1Postpaid
                default:
                    return v1Postpaid
            }
        },
        me() {
            return this.$store.getters.me
        },
        mode() {
            return this.userContract.is_complete ? 'import' : 'create'
        },
    },
    methods: {
        async back() {
            if (this.sign && this.signStatus.mode === 'create') {
                const idx = await this.$modal.basic({
                    body: '서명을 그만두시겠습니까? 서명한 내용이 저장되지 않습니다',
                    preventCloseOnMousedownMask: true,
                    buttons: [
                        {
                            label: '아니오',
                            class: 'btn-default',
                        },
                        {
                            label: '네',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (!idx) return
            }
            this.$stackRouter.pop()
        },
        getUserSign({ dataUrl, imgBlob }) {
            this.sign = dataUrl
            this.imgBlob = imgBlob
        },
        async submitContract() {
            try {
                const payload = new FormData()
                const userRatePlans = this.$store.getters.userRatePlans.pending[0]

                // payload.append('version', 3)
                const { plan_type: planType } = getPlanNameById(this.userContract.product_id)
                payload.append('version', planType === 'postpaid' ? 1 : 4)
                payload.append('user_product_id', userRatePlans.id)
                payload.append('sign_photo', this.imgBlob)
                // payload.append('truth_complete', false)

                // 이건 싸인 제출
                const { res, data } = await userContractService.update(
                    this.me.id,
                    (this.me.user_contract || {}).id,
                    payload
                )
                if (res === 'success') {
                    // this.$toast.success('작성 완료! 계약서는 마이페이지에서 확인할 수 있습니다.')
                    this.$set(this.me, 'user_contract', data)
                    this.$stackRouter.pop()
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.contract-container {
    line-height: 24px;
    font-size: 14px;

    .contract-container-main {
        padding: 16px;
        height: calc(100vh - 110px);
        overflow-y: auto;

        &.import {
            height: calc(100vh - 52px);
        }
    }
}
</style>
