<template>
    <div class="make-contract">
        <ContractV2 v-if="userContract" :user-contract="userContract" />
    </div>
</template>

<script>
// import ContractV1 from '@/routes/my-page/components/ContractV1'
import ContractV2 from '@/routes/my-page/components/contract/ContractV2'
import userContractService from '@/services/user-contract'

export default {
    name: 'MakeContractPage',
    components: {
        // ContractV1,
        ContractV2,
    },
    data: () => ({
        userContract: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    async mounted() {
        try {
            const { data } = await userContractService.get(this.me.id, (this.me.user_contract || {}).id)
            this.userContract = data || {}
        } catch (e) {
            this.userContract = {}
        }
    },
}
</script>

<style scoped lang="scss">
.make-contract {
    .title {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
        color: black;
        @include spoqa-f-bold;
    }

    .content {
        //margin-bottom: 24px;

        .item {
            display: flex;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 24px;
        }
    }

    .contract {
        height: 280px;
        border: 4px solid $grey-03;
        overflow-y: scroll;
    }
}
</style>
